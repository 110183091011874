import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import healingCover from "../images/books/healing_front_cover.png"
import angleRight from "../images/common/angle-right-white.svg"
import feather from "../images/home/feather.png"
import seoContent from "../helpers/seoContent"

const Books = () => {
  const { siteDescriptions } = seoContent
  return (
    <Layout>
      <SEO title="Books" description={siteDescriptions.default} />
      <div id="books">
        <div className="books-header">
          <h1>Books</h1>
        </div>
        <div className="books-body">
          <div className="books-content-item">
            <div className="books-content">
              <div className="books-content-title">Healing</div>
              <div className="book-about-section">
                <div className="book-about-section-text">
                  Gabriella's long-awaited collection of poems, "Healing", is
                  now available!
                </div>
              </div>
              <div className="book-about-section">
                <div className="book-about-section-title">About Healing</div>
                <div className="book-about-section-text">
                  “Healing” is a collection of love poems about a woman’s
                  journey to overcome pain and discover her inner power through
                  self-love and spirituality. The book is divided into five
                  sections: My Solitude, My Pain, My Passion, My Power, and My
                  Spirituality. Each poem in the edition is paired with magical
                  illustrations, including gorgeous imagery of flowers and
                  nature that bring the words to life.
                  <br />
                  <br />
                  You can see more of Gabriella's work by following her on
                  Instagram{" "}
                  <a
                    className="book-link"
                    href="https://www.instagram.com/gabriellaalziari/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    @gabriellaalziari
                  </a>
                  .
                </div>
              </div>
              <div className="book-about-section">
                <div className="book-about-section-title">
                  Words About Healing
                </div>
                <div
                  className="book-about-section-text"
                  style={{ marginTop: "10px" }}
                >
                  <i className="fa fa-quote-left" aria-hidden="true"></i>
                  &nbsp;&nbsp;Reading and getting lost in her beautiful &
                  expressive verse helps heal the void that came on so quickly
                  with the pandemic. Depending on the moment, I choose to read
                  from one of the five chapters. The ones that resonate the most
                  are: My Solitude, My Power, My Spirituality. Gabriella writes
                  with intimacy, transparency and a spoonful of humor. The poems
                  evolve from the beginning to the end, telling the story of
                  finding love within in order to find/see love in others. She
                  threads references of her sensuality and nature throughout. A
                  collection that came just at the right time to soothe and
                  envelop us in a comfy blanket. Thank you Gabriella. You are
                  wise beyond your years.&nbsp;&nbsp;
                  <i className="fa fa-quote-right" aria-hidden="true"></i>
                </div>
                <div
                  className="book-about-section-text"
                  style={{ marginTop: "10px" }}
                >
                  <i className="fa fa-quote-left" aria-hidden="true"></i>
                  &nbsp;&nbsp;“Healing” is incredibly moving and empowering. We
                  are all healing from our own type of wounds. The author’s
                  words evoke a sense of courageousness in knowing that there is
                  light at the end of the tunnel.&nbsp;&nbsp;
                  <i className="fa fa-quote-right" aria-hidden="true"></i>
                </div>
              </div>
              <div className="books-content-cta-title">Find Healing at</div>
              <a
                className="learn-btn"
                href="https://www.amazon.com/Healing-collection-poems-Gabriella-Alziari/dp/0578616513/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Amazon</span>
                <img
                  className="arrow"
                  src={angleRight}
                  alt="angle-right-icon"
                />
              </a>
              <div className="follow section">
                <div className="title">Follow Her</div>
                <div className="content">
                  <div className="social-media-wrap">
                    <a
                      className="social-media"
                      href="https://www.facebook.com/Gabriella-Alziari-533682247139232/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a
                      className="social-media"
                      href="https://www.instagram.com/gabriellaalziari/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a
                      className="social-media"
                      href="https://www.linkedin.com/in/gabbyalziari/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                  <img className="feather" src={feather} />
                </div>
              </div>
            </div>
            <div className="books-image-wrap">
              <img className="books-image" src={healingCover} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Books
